.pswdContainer {
  vertical-align: bottom;
}

.labPassword {
  display: block;
  color: white;
  font-weight: bold;
 }

.inpPassword {
  font: inherit;
  background-color: #f1e1fc;
  color: #38015c;
  border-radius: 4px;
  border: 1px solid white;
  width: 65%;
  text-align: left;
  padding: 0.25rem;
  margin-right: 0.5rem;
}

.imgHideUnhide {
  max-width: 5%;
  height: auto;
  margin-bottom: -1.4%;
 }