/*********** TAG SELECTORS ***********/
td {
  padding-right: 10px;
}
/*********** CLASS SELECTORS : .xxx {} ***********/
.headerBar {
    background-color: white;
}
.grid-container {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-gap: 20px;
}
.logo {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 1;
}
.title {
  grid-column-start: 2;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 1;
  color: blue;
  text-align: center;
}
.env {
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 1;
  padding-top: 20px;
  padding-right: 5px;
}
.noborders {
  border: 0;
}
/*********** ID SELECTORS: #xxx {} ***********/
#hdrTable {
  border: hidden;
  margin-right: 0px;
  margin-left: auto;
}