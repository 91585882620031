.starting {
  margin: 3rem auto;
  text-align: center;
}
.cookieConsent {
  font-style: italic;
}
.incident{
  color: red;
  text-align: left;
}

