/*********** TAG SELECTORS ***********/

/*********** CLASS SELECTORS : .xxx {} ***********/

/*********** ID SELECTORS: #xxx {} ***********/
#nextIssue {
    padding-left: 10%;
    padding-right: 10%;
    /* position: relative;
    bottom: -90%; */
}

#divNext {
    text-align: center;
}

#tabNext {
    table-layout: auto;
    margin-left:25%;
    margin-right:25%
}

#lblIx {
    width: 40%;
}

#lblStep {
    width: 40%;
}
#step {
    width: 55%;
    background-color: rgb(86, 247, 86);
}
