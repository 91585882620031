/*********** TAG SELECTORS ***********/

/*********** CLASS SELECTORS : .xxx {} ***********/


.header {
    width: 100%;
    height: 5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #008080;
  }
  
  .logo {
    font-size: 2rem;
    color: white;
   }
  
  .nav ul {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
  }
  
  .nav li {
    margin-left: 1.5rem;
    font-size: 1.25rem;
  }
  
  .nav a {
    text-decoration: none;
    color: #88dfdf;
    padding-left: 10 px;
  }
  
  .nav a:hover,
  .nav a:active,
  .nav a.active {
    color: #e6fcfc;
  }
/*********** ID SELECTORS: #xxx {} ***********/
