/*********** TAG SELECTORS ***********/

/*********** CLASS SELECTORS : .xxx {} ***********/
.tricky_button {
    margin-right:16px;
}
  
.header_row {
    font-weight: bold;
}
/*********** ID SELECTORS: #xxx {} ***********/
