/*********** TAG SELECTORS ***********/

/*********** CLASS SELECTORS : .xxx {} ***********/

/*********** ID SELECTORS: #xxx {} ***********/
#prevIssue {
    padding-left: 10%;
    padding-right: 10%;
    /* position: relative;
    bottom: -90%; */
}
#divPrev {
    text-align: center;
}
#tabPrev {
    table-layout: auto;
    margin-left:25%;
    margin-right:25%
}

#lblIxPrev {
    width: 40%;
}

#lblStepBack {
    width: 35%;
}
#stepBack {
    width: 60%;
    background-color: #ccf2f4;
}