@import url(https://fonts.googleapis.com/css2?family=Lato:wght@700&family=Open+Sans:wght@400;700&display=swap);
/*********** TAG SELECTORS ***********/

/*********** CLASS SELECTORS : .xxx {} ***********/

/*********** ID SELECTORS: #xxx {} ***********/
  
/*********** TAG SELECTORS ***********/

/*********** CLASS SELECTORS : .xxx {} ***********/


.MainNavigation_header__2lqQ1 {
    width: 100%;
    height: 5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #008080;
  }
  
  .MainNavigation_logo__1TgMx {
    font-size: 2rem;
    color: white;
   }
  
  .MainNavigation_nav__3DXok ul {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
  }
  
  .MainNavigation_nav__3DXok li {
    margin-left: 1.5rem;
    font-size: 1.25rem;
  }
  
  .MainNavigation_nav__3DXok a {
    text-decoration: none;
    color: #88dfdf;
    padding-left: 10 px;
  }
  
  .MainNavigation_nav__3DXok a:hover,
  .MainNavigation_nav__3DXok a:active,
  .MainNavigation_nav__3DXok a.MainNavigation_active__ydtOF {
    color: #e6fcfc;
  }
/*********** ID SELECTORS: #xxx {} ***********/

/*********** TAG SELECTORS ***********/
td {
  padding-right: 10px;
}
/*********** CLASS SELECTORS : .xxx {} ***********/
.headerBar {
    background-color: white;
}
.grid-container {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-gap: 20px;
}
.logo {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 1;
}
.title {
  grid-column-start: 2;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 1;
  color: blue;
  text-align: center;
}
.env {
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 1;
  padding-top: 20px;
  padding-right: 5px;
}
.noborders {
  border: 0;
}
/*********** ID SELECTORS: #xxx {} ***********/
#hdrTable {
  border: hidden;
  margin-right: 0px;
  margin-left: auto;
}
.StartingPageContent_starting__2Htbr {
  margin: 3rem auto;
  text-align: center;
}
.StartingPageContent_cookieConsent__SxWAw {
  font-style: italic;
}
.StartingPageContent_incident__GoUEW{
  color: red;
  text-align: left;
}


/*********** TAG SELECTORS ***********/

/*********** CLASS SELECTORS : .xxx {} ***********/

/*********** ID SELECTORS: #xxx {} ***********/

/*********** TAG SELECTORS ***********/

/*********** CLASS SELECTORS : .xxx {} ***********/
.question {
    font-weight: bold;
}
/*********** ID SELECTORS: #xxx {} ***********/
/*********** TAG SELECTORS ***********/

/*********** CLASS SELECTORS : .xxx {} ***********/

/*********** ID SELECTORS: #xxx {} ***********/

/*********** TAG SELECTORS ***********/

/*********** CLASS SELECTORS : .xxx {} ***********/

/*********** ID SELECTORS: #xxx {} ***********/

/*********** TAG SELECTORS ***********/

/*********** CLASS SELECTORS : .xxx {} ***********/

/*********** ID SELECTORS: #xxx {} ***********/
#nextIssue {
    padding-left: 10%;
    padding-right: 10%;
    /* position: relative;
    bottom: -90%; */
}

#divNext {
    text-align: center;
}

#tabNext {
    table-layout: auto;
    margin-left:25%;
    margin-right:25%
}

#lblIx {
    width: 40%;
}

#lblStep {
    width: 40%;
}
#step {
    width: 55%;
    background-color: rgb(86, 247, 86);
}

/*********** TAG SELECTORS ***********/

/*********** CLASS SELECTORS : .xxx {} ***********/

/*********** ID SELECTORS: #xxx {} ***********/
#prevIssue {
    padding-left: 10%;
    padding-right: 10%;
    /* position: relative;
    bottom: -90%; */
}
#divPrev {
    text-align: center;
}
#tabPrev {
    table-layout: auto;
    margin-left:25%;
    margin-right:25%
}

#lblIxPrev {
    width: 40%;
}

#lblStepBack {
    width: 35%;
}
#stepBack {
    width: 60%;
    background-color: #ccf2f4;
}
/*********** TAG SELECTORS ***********/

/*********** CLASS SELECTORS : .xxx {} ***********/

/*********** ID SELECTORS: #xxx {} ***********/

/*********** TAG SELECTORS ***********/

/*********** CLASS SELECTORS : .xxx {} ***********/
.warningMsg {
    color: red;
}
/*********** ID SELECTORS: #xxx {} ***********/
/*********** TAG SELECTORS ***********/

/*********** CLASS SELECTORS : .xxx {} ***********/
.warningMsg {
    color: red;
}
/*********** ID SELECTORS: #xxx {} ***********/
/*********** TAG SELECTORS ***********/

/*********** CLASS SELECTORS : .xxx {} ***********/
.footer {
  text-align: center;
}

/*********** ID SELECTORS: #xxx {} ***********/

/* .auth {
  margin: 3rem auto;
  width: 95%;
  max-width: 25rem;
  border-radius: 6px;
  background-color: #38015c;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 1rem;
  text-align: center;
  color:white;
}

.auth h1 {
  text-align: center;
  color: white;
}

.control {
  margin-bottom: 0.5rem;
}

.control label {
  display: block;
  color: white;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.control input {
  font: inherit;
  background-color: #f1e1fc;
  color: #38015c;
  border-radius: 4px;
  border: 1px solid white;
  width: 100%;
  text-align: left;
  padding: 0.25rem;
}

.actions {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
} */

button {
  cursor: pointer;
  font: inherit;
  color: white;
  background-color: #9f5ccc;
  border: 1px solid #9f5ccc;
  border-radius: 4px;
  padding: 0.5rem 2.5rem;
}

button:hover {
  background-color: #873abb;
  border-color: #873abb;
}
button:disabled { 
  background-color: Grey;
} 
/* .toggle {
  margin-top: 1rem;
  background-color: transparent;
  color: #9f5ccc;
  border: none;
  padding: 0.15rem 1.5rem;
}

.toggle:hover {
  background-color: transparent;
  color: #ae82cc;
} */

/*********** TAG SELECTORS ***********/

/*********** CLASS SELECTORS : .xxx {} ***********/
.tricky_button {
    margin-right:16px;
}
  
.header_row {
    font-weight: bold;
}
/*********** ID SELECTORS: #xxx {} ***********/

/*********** TAG SELECTORS ***********/

/*********** CLASS SELECTORS : .xxx {} ***********/

/*********** ID SELECTORS: #xxx {} ***********/

/*********** TAG SELECTORS ***********/

/*********** CLASS SELECTORS : .xxx {} ***********/
.warningMsg {
    color: red;
}
/*********** ID SELECTORS: #xxx {} ***********/

/*********** TAG SELECTORS ***********/

/*********** CLASS SELECTORS : .xxx {} ***********/

/*********** ID SELECTORS: #xxx {} ***********/

/*********** TAG SELECTORS ***********/

/*********** CLASS SELECTORS : .xxx {} ***********/

/*********** ID SELECTORS: #xxx {} ***********/

.LoginForm_auth__IxfFh {
  margin: 3rem auto;
  width: 95%;
  max-width: 50rem;
  border-radius: 6px;
  background-color: #38015c;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 1rem;
  text-align: center;
  color:white;
}

.LoginForm_auth__IxfFh h1 {
  text-align: center;
  color: white;
}

.LoginForm_control__1Rz1s {
  margin-bottom: 0.5rem;
}

.LoginForm_control__1Rz1s label {
  display: block;
  color: white;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.LoginForm_control__1Rz1s input {
  font: inherit;
  background-color: #f1e1fc;
  color: #38015c;
  border-radius: 4px;
  border: 1px solid white;
  width: 100%;
  text-align: left;
  padding: 0.25rem;
}

.LoginForm_actions__1iohu {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.LoginForm_actions__1iohu button {
  cursor: pointer;
  font: inherit;
  color: white;
  background-color: #9f5ccc;
  border: 1px solid #9f5ccc;
  border-radius: 4px;
  padding: 0.5rem 2.5rem;
}

.LoginForm_actions__1iohu button:hover {
  background-color: #873abb;
  border-color: #873abb;
}

.LoginForm_actions__1iohu .LoginForm_toggle__zsmPl {
  margin-top: 1rem;
  background-color: transparent;
  color: #9f5ccc;
  border: none;
  padding: 0.15rem 1.5rem;
}

.LoginForm_actions__1iohu .LoginForm_toggle__zsmPl:hover {
  background-color: transparent;
  color: #ae82cc;
}

.Password_pswdContainer__3isJu {
  vertical-align: bottom;
}

.Password_labPassword__1wk3L {
  display: block;
  color: white;
  font-weight: bold;
 }

.Password_inpPassword__3ZY1x {
  font: inherit;
  background-color: #f1e1fc;
  color: #38015c;
  border-radius: 4px;
  border: 1px solid white;
  width: 65%;
  text-align: left;
  padding: 0.25rem;
  margin-right: 0.5rem;
}

.Password_imgHideUnhide__31AmN {
  max-width: 5%;
  height: auto;
  margin-bottom: -1.4%;
 }
.ChangePasswordForm_auth__2QgU7 {
  margin: 3rem auto;
  width: 95%;
  max-width: 50rem;
  border-radius: 6px;
  background-color: #38015c;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 1rem;
  text-align: center;
  color:white;
}

.ChangePasswordForm_auth__2QgU7 h1 {
  text-align: center;
  color: white;
}

.ChangePasswordForm_control__hyFtF {
  margin-bottom: 0.5rem;
}

.ChangePasswordForm_control__hyFtF label {
  display: block;
  color: white;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.ChangePasswordForm_control__hyFtF input {
  font: inherit;
  background-color: #f1e1fc;
  color: #38015c;
  border-radius: 4px;
  border: 1px solid white;
  width: 100%;
  text-align: left;
  padding: 0.25rem;
}

.ChangePasswordForm_actions__DpUuw {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ChangePasswordForm_actions__DpUuw button {
  cursor: pointer;
  font: inherit;
  color: white;
  background-color: #9f5ccc;
  border: 1px solid #9f5ccc;
  border-radius: 4px;
  padding: 0.5rem 2.5rem;
}

.ChangePasswordForm_actions__DpUuw button:hover {
  background-color: #873abb;
  border-color: #873abb;
}

.ChangePasswordForm_actions__DpUuw .ChangePasswordForm_toggle__iWKwT {
  margin-top: 1rem;
  background-color: transparent;
  color: #9f5ccc;
  border: none;
  padding: 0.15rem 1.5rem;
}

.ChangePasswordForm_actions__DpUuw .ChangePasswordForm_toggle__iWKwT:hover {
  background-color: transparent;
  color: #ae82cc;
}

.ResetPasswordForm_auth__35dh7 {
  margin: 3rem auto;
  width: 95%;
  max-width: 25rem;
  border-radius: 6px;
  background-color: #38015c;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 1rem;
  text-align: center;
  color:white;
}

.ResetPasswordForm_auth__35dh7 h1 {
  text-align: center;
  color: white;
}

.ResetPasswordForm_control__2pC6Y {
  margin-bottom: 0.5rem;
}

.ResetPasswordForm_control__2pC6Y label {
  display: block;
  color: white;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.ResetPasswordForm_control__2pC6Y input {
  font: inherit;
  background-color: #f1e1fc;
  color: #38015c;
  border-radius: 4px;
  border: 1px solid white;
  width: 100%;
  text-align: left;
  padding: 0.25rem;
}

.ResetPasswordForm_actions__3B8X9 {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ResetPasswordForm_actions__3B8X9 button {
  cursor: pointer;
  font: inherit;
  color: white;
  background-color: #9f5ccc;
  border: 1px solid #9f5ccc;
  border-radius: 4px;
  padding: 0.5rem 2.5rem;
}

.ResetPasswordForm_actions__3B8X9 button:hover {
  background-color: #873abb;
  border-color: #873abb;
}

.ResetPasswordForm_actions__3B8X9 .ResetPasswordForm_toggle__19s3i {
  margin-top: 1rem;
  background-color: transparent;
  color: #9f5ccc;
  border: none;
  padding: 0.15rem 1.5rem;
}

.ResetPasswordForm_actions__3B8X9 .ResetPasswordForm_toggle__19s3i:hover {
  background-color: transparent;
  color: #ae82cc;
}

.DownloadForm_auth__3VrT6 {
  margin: 3rem auto;
  width: 95%;
  max-width: 25rem;
  border-radius: 6px;
  background-color: #38015c;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 1rem;
  text-align: center;
  color:white;
}

.DownloadForm_auth__3VrT6 h1 {
  text-align: center;
  color: white;
}

.DownloadForm_control__1U22a {
  margin-bottom: 0.5rem;
}

.DownloadForm_control__1U22a label {
  display: block;
  color: white;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.DownloadForm_control__1U22a input {
  font: inherit;
  background-color: #f1e1fc;
  color: #38015c;
  border-radius: 4px;
  border: 1px solid white;
  width: 100%;
  text-align: left;
  padding: 0.25rem;
}

.DownloadForm_actions__3nfD6 {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.DownloadForm_actions__3nfD6 button {
  cursor: pointer;
  font: inherit;
  color: white;
  background-color: #9f5ccc;
  border: 1px solid #9f5ccc;
  border-radius: 4px;
  padding: 0.5rem 2.5rem;
}

.DownloadForm_actions__3nfD6 button:hover {
  background-color: #873abb;
  border-color: #873abb;
}

.DownloadForm_actions__3nfD6 .DownloadForm_toggle__1VMAP {
  margin-top: 1rem;
  background-color: transparent;
  color: #9f5ccc;
  border: none;
  padding: 0.15rem 1.5rem;
}

.DownloadForm_actions__3nfD6 .DownloadForm_toggle__1VMAP:hover {
  background-color: transparent;
  color: #ae82cc;
}

.DownloadForm_yyyymmdd__FxFqp {
  width: 85px;
}
.Municipalities_municipalityList__2s39q {
  text-align: center;
}

* {
  box-sizing: border-box;
}

body {
  font-family: 'Open Sans', 'Lato', sans-serif;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Lato', sans-serif;
}

